/* eslint-disable */
import React from 'react';
import ListContainer, { ListProps } from '../../core/ListContainer';

import IoTDataService from './iotdata.service';
import { useSnackbar } from 'notistack';

const service = new IoTDataService();
const defaultConfig = {
  service,
  filterName: 'IoTDatas',
  sortName: 'IoTDatas',
  paginate: true,
  limit: 50
};

interface IoTDataProps extends ListProps {
  api?: any;
  enqueueSnackbar: any;
}

class IoTDatasList extends ListContainer<IoTDataProps> {
  constructor(props, config) {
    super(props, Object.assign(defaultConfig, config));
    this.props.api.refresh = this.refresh;
  }

  componentDidMount() {
    ///start:slot:load<<<
    this.load('OrderByDesc=Id');
    ///end:slot:load<<<
  }

  AFTER_LOAD = (baseList: Array<any>) => {
    this.success('Refreshed.');
  };

  render() {
    const { isLoading, baseEntity, baseList, filterOptions, isDisabled } = this.state;

    return (
      <table className='table' style={{ fontSize: '.8em' }}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Employee</th>
            <th>Station</th>
          </tr>
        </thead>
        <tbody>
          {baseList &&
            baseList.map((item, index) => (
              <tr key={item.Id}>
                <td>
                  {this.formatDate(item.ConvertedCreatedAt)} {this.formatTime(item.ConvertedCreatedAt)}
                </td>
                <td>{item.User}</td>
                <td>{item.Station}</td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  }
}

const IoTData = props => {
  const snackbar = useSnackbar();
  const { enqueueSnackbar } = snackbar;
  return <IoTDatasList {...props} enqueueSnackbar={enqueueSnackbar} />;
};

export default IoTData;
