import 'isomorphic-fetch';
import { Subject } from 'rxjs';
import AppConfig from './AppConfig';
import { IAuth } from './Contract';

const request = async (method: string, endpoint: string) => {
  return await fetch(AppConfig.AuthURL + endpoint, {
    method: method,
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export default class AuthService {
  static auth?: IAuth;

  static fillAuthData = () => {
    var currentAuth = AuthService.auth;
    AuthService.auth = JSON.parse(localStorage.getItem('auth') || 'null');
    if (currentAuth?.user?.UserName !== AuthService.auth?.user?.UserName) {
      AuthService.OnAuthChange.next();
    }

    return AuthService.auth;
  };

  static store = auth => {
    localStorage.setItem('auth', JSON.stringify(auth));

    AuthService.OnAuthChange.next();
  };

  static login = async (username: string, password: string) => {
    let data = `username=${username}&password=${password}`;
    try {
      let response = await request('POST', 'auth/credentials.json?' + data);
      if (!response.ok) throw new Error('Invalid');
      let userAuth = await response.json();
      AuthService.auth = {
        user: userAuth
      };
      AuthService.store(AuthService.auth);
      return AuthService.ResolveLoginPromise(AuthService.auth);
    } catch (e) {
      throw new Error('Invalid Username or Password.');
    }
  };

  static setAccountLocalStorage = user => {
    AuthService.fillAuthData();
    if (AuthService.auth) {
      AuthService.auth.user = user;
      AuthService.store(AuthService.auth);
    }
  };

  static logout = () => {
    localStorage.removeItem('auth');
    AuthService.auth = undefined;
    AuthService.OnAuthChange.next();
    request('GET', 'auth/logout');
  };

  static hasRole = (role: string) => {
    return AuthService.auth?.user?.Roles?.some(r => r === role);
  };

  static ON_LOGIN = () => {};

  static OpenLogin = () => {};

  static OnAuthChange = new Subject();

  static ResolveLoginPromise(result: any) {}

  static RequestLogin = async () => {
    AuthService.OpenLogin();
    return new Promise((resolve, reject) => {
      AuthService.ResolveLoginPromise = resolve;
    });
  };
}
