/* eslint-disable */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, isPlatform, getPlatforms } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { ellipse, square, triangle } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

import './bootstrap.css';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { Container } from '@material-ui/core';

const App: React.FC = () => {
  const isDesktop = !(isPlatform('mobile'));
  console.log(getPlatforms());

  const desktopStyle = {
    position: 'absolute',
    height: '100%',
    maxHeight: 800,
    width: '100%',
    maxWidth: 800,
    margin: 'auto',
    boxShadow: '0px 0px 20px #888888'
  };

  return (
    <IonApp style={isDesktop ? desktopStyle : {}}>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <Route path='/stationLogin' component={Tab1} exact={true} />
            <Route path='/data' component={Tab2} exact={true} />
            <Route path='/nodes' component={Tab3} />
            <Route path='/' render={() => <Redirect to='/stationLogin' />} exact={true} />
          </IonRouterOutlet>
          <IonTabBar slot='bottom'>
            <IonTabButton tab='tab1' href='/stationLogin'>
              <IonIcon icon={triangle} />
              <IonLabel>Station Login</IonLabel>
            </IonTabButton>
            <IonTabButton tab='tab2' href='/data'>
              <IonIcon icon={ellipse} />
              <IonLabel>Data</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
