/* eslint-disable */
import React, { useState } from 'react';
import AuthService from '../core/AuthService';
import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonInput,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import { lockClosed } from 'ionicons/icons';

const Login = (props: any) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [ex, setEx] = useState('');

  const login = async (event: any) => {
    event.preventDefault();
    try {
      await AuthService.login(username, password);
      props.onCloseLogin();
    } catch (e) {
      setError(e.message);
      setEx(e);
    }
  };

  return (
    <>
      <div style={{ maxWidth: 300, margin: 'auto' }}>
        <IonGrid fixed>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle className='ion-text-center'>IoT-Operator</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                  <form
                    noValidate
                    onSubmit={event => {
                      setError('');
                      login(event);
                    }}
                  >
                    <IonItem>
                      <IonLabel position='stacked'>User Name</IonLabel>
                      <IonInput value={username} onIonChange={e => setUsername(e.detail.value!)} />
                    </IonItem>
                    <IonItem>
                      <IonLabel position='stacked'>Password</IonLabel>
                      <IonInput type='password' value={password} onIonChange={e => setPassword(e.detail.value!)} />
                    </IonItem>
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                      <IonButton fill='solid' shape='round' type='submit' size='default'>
                        <IonIcon slot='start' icon={lockClosed} />
                        Login
                      </IonButton>
                    </div>
                  </form>
                  <IonItem lines='none'>
                    <IonLabel color='danger'>{error && <p>{error}</p>}</IonLabel>
                  </IonItem>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default Login;
