/* eslint-disable */
import { IonAlert, IonButton, IonButtons, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { createMuiTheme, Grid, ThemeProvider } from '@material-ui/core';
import { personCircle } from 'ionicons/icons';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import AuthService from '../../core/AuthService';
import { IAuth } from '../../core/Contract';
import Dialog from '../../widgets/Dialog';
import Login from '../../widgets/Login';

interface IIndexState {
  auth?: IAuth;
  anchorEl?: any;
  currentTab: boolean;
  loginOpen: boolean;
  loading: boolean;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#757575',
      main: '#871707',
      dark: '#002884',
      contrastText: '#fff'
    },
    secondary: {
      light: '#c62828',
      main: '#c7c7c7',
      dark: '#ba000d',
      contrastText: '#fff'
    }
    // type: 'dark'
  }
});

class App extends React.Component {
  pages = [
    ///start:slot:pages<<<///end:slot:pages<<<
  ];

  state: IIndexState = {
    auth: undefined,
    anchorEl: null,
    currentTab: false,
    loginOpen: false,
    loading: true
  };

  classes = {
    root: {
      flexGrow: 1
    },
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: -12,
      marginRight: 20
    }
  };

  componentDidMount() {
    AuthService.fillAuthData();
    AuthService.OpenLogin = this.openLoginDialog;
    AuthService.OnAuthChange.subscribe(() => {
      this.forceUpdate();
      if (!AuthService.auth) {
        AuthService.RequestLogin();
      }
    });
    if (!AuthService.auth) {
      AuthService.RequestLogin();
    }

    let currentPath = window.location.pathname;
    let findRoute = this.pages.findIndex(e => (e as any).href.toLowerCase() === currentPath.toLowerCase());

    this.setState({
      loading: false,
      currentTab: findRoute > -1 ? findRoute : false
    });
  }

  openLoginDialog = () => {
    this.setState({ loginOpen: true });
  };
  closeLoginDialog = () => {
    this.setState({ loginOpen: false });
  };

  logout = () => {
    AuthService.logout();
    AuthService.RequestLogin();
    this.setState({ anchorEl: null });
  };

  handleProfile = (event: any) => {
    this.setState({});
    this.handleClose();
  };

  toggleDrawer = (side: string, open: boolean) => () => {
    this.setState({
      [side]: open
    });
  };
  handleMenu = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleTabsChange = (event: any, newValue: number) => {
    this.setState({ currentTab: newValue });
  };

  closeDialog() {
    alert('close dialog');
  }

  render() {
    const auth = AuthService.auth;
    return (
      <ThemeProvider theme={theme}>
        <Dialog open={this.state.loginOpen} onClose={this.closeLoginDialog} fullScreen actionsOff>
          {() => <Login onCloseLogin={this.closeLoginDialog} />}
        </Dialog>
        <IonAlert
          isOpen={(this.state as any).signout}
          header={'Sign out?'}
          // message={'Message <strong>text</strong>!!!'}
          onDidDismiss={() => {
            this.setState({ signout: false });
          }}
          buttons={[
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary'
              // handler: blah => {
              //   console.log('Confirm Cancel: blah');
              // }
            },
            {
              text: 'OK',
              handler: () => {
                this.logout();
              }
            }
          ]}
        />
        {/* <IonSelect style={{ display: 'none' }} interface='action-sheet' onIonChange={() => this.logout()}>
          <IonSelectOption>Sign Out</IonSelectOption>
        </IonSelect> */}
        <SnackbarProvider autoHideDuration={1000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} maxSnack={4}>
          <IonToolbar color='light'>
            <IonTitle>IoT-Operator</IonTitle>
            <IonButtons slot='primary'>
              <IonButton onClick={() => this.setState({ signout: true })}>
                <IonIcon slot='icon-only' icon={personCircle} />
              </IonButton>
            </IonButtons>
          </IonToolbar>

          <Grid
            container
            direction='column'
            item
            xs={12}
            style={{ paddingTop: '2%', visibility: this.state.loading ? 'hidden' : 'visible' }}
          >
            {this.props.children}
          </Grid>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

export default App;
