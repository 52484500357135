/* eslint-disable */
import React from 'react';
import {
  IonListHeader,
  IonSearchbar,
  IonContent,
  IonList,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonRadio,
  IonInput,
  IonButton,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonItemDivider
} from '@ionic/react';
import Dialog from '../widgets/Dialog';

interface Props {
  onChange(entity: any, targetProp: string, newValue: any): any;
  owner: any;
  options: any[];
  targetProp: string;
  label?: string;
  disabled?: boolean;
  displayValue?: string;
  flat?: boolean;
  callback?(entity: any): any;
  fromProp?: string;
  autoSelect?: boolean;
}

class AutocompleteWidget extends React.Component<Props> {
  state = {
    searchText: '',
    value: null,
    rawValue: null,
    autocompleteOpened: false,
    found: null
  };

  setSearchText = (searchText: string) => {
    this.setState({ searchText });
  };

  _onChange = (event: any, value: any = {}) => {
    const { flat, targetProp, callback, owner, fromProp = 'Value', displayValue = 'displayValue' } = this.props;

    let entity = { ...owner };

    if (flat) entity[targetProp] = value;
    else if (callback) callback(entity);
    else if (fromProp) {
      if (value === null) {
        entity[targetProp] = null;
        entity['_' + targetProp] = null;
      } else {
        entity[targetProp] = value[fromProp];
        entity['_' + targetProp] = value;
      }
    }

    this.setState({ value, rawValue: flat ? value : value[displayValue], selected: value });

    this.props.onChange(entity, targetProp, value);
  };

  shouldComponentUpdate(nextProps: any, nextState: any) {
    const { options: nextOptions, owner: nextOwner } = nextProps;
    const { options, owner, targetProp, flat, fromProp = 'Value' } = this.props;

    const { value: nextValue, autocompleteOpened: nextautocompleteOpened, searchText: nextSearchText, found: nextFound } = nextState;
    const { value, autocompleteOpened, searchText, found } = this.state;

    if (nextOptions !== options) {
      // console.log(targetProp + ' different options, will render');
      return true;
    } else if (nextValue !== value) {
      // console.log(targetProp + ' different value, will render');
      // console.log('value:', value);
      // console.log('next value:', nextValue);
      return true;
    } else if (owner[targetProp] !== nextOwner[targetProp]) {
      // console.log(targetProp + ' different owner[targetProp], will render');
      // console.log('owner[targetProp]:', owner[targetProp]);
      // console.log('nextOwner[targetProp]:', nextOwner[targetProp]);
      return true;
    }

    if (flat) {
      if (nextValue !== nextOwner[targetProp]) {
        return true;
      }
    } else if (fromProp && nextOwner && nextValue && nextValue[fromProp] !== nextOwner[targetProp]) {
      return true;
    }

    if (autocompleteOpened !== nextautocompleteOpened) {
      // console.log('autocompleteOpened', autocompleteOpened, 'nextautocompleteOpened', nextautocompleteOpened);
      return true;
    }

    if (searchText != nextSearchText) return true;

    if (found != nextFound) return true;

    return false;
  }

  componentDidMount() {
    const { options, flat, targetProp, callback, owner, fromProp = 'Value' } = this.props;

    let value = null;
    if (flat) {
      value = owner[targetProp];
      this.setState({ value });
    } else {
      if (owner && targetProp && options) {
        let found = options.find(o => o[fromProp] === owner[targetProp]);
        if (found) {
          value = found;
          this.setState({ value });
        }
      }
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { owner, targetProp, flat, options, fromProp = 'Value', callback, onChange } = this.props;

    const { value: prevValue } = prevState;
    const { value } = this.state;

    if (owner && owner['_' + targetProp] !== value) {
      // if (owner && owner[targetProp] !== prevValue) {
      var entity = { ...owner };

      if (flat) {
        this.setState({ value: entity[targetProp] });
        onChange(entity, targetProp, value);
      } else if (callback) {
        //TODO
        // callback(owner);
      } else {
        if (owner[targetProp] === null) {
          this.setState({ value: null });
          onChange(entity, targetProp, null);
        } else {
          let found = options?.find(o => o[fromProp] === entity[targetProp]);
          if (found) {
            // this._onChange(null, found);
            this.setState({ value: found });
            onChange(entity, targetProp, value);
          } else {
            // this._onChange(null, entity[targetProp]);
            // console.log('not found');
          }
        }
      }
    }
  }

  closeDialog = (propId: string) => {
    this.setState({
      [propId]: false
    });
  };

  selectItem = (dialog, item) => {
    // console.log('selected:', (this.state as any).selected);
    if (item) {
      this._onChange(null, item);
      // this.setSearchText(flat ? value : value[displayValue]);
      dialog.close('ok');
    }
  };

  render() {
    const {
      options = [],
      targetProp,
      label = targetProp,
      disabled,
      displayValue = 'displayValue',
      flat = false,
      autoSelect,
      fromProp = 'Value'
    } = this.props;
    const { searchText, value, isDialogOpen, rawValue, found } = this.state as any;

    return (
      <>
        <IonListHeader>
          <IonLabel>{label}</IonLabel>
        </IonListHeader>

        <IonItem style={{ color: !found ? 'red' : 'initial' }}>
          <IonInput
            value={rawValue}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
            }}
            onIonChange={e => {
              let found;
              if (flat) found = options?.find(o => o === e.detail.value!);
              else found = options?.find(o => o[displayValue] === e.detail.value! || o[fromProp] === e.detail.value!);

              if (found) {
                this._onChange(null, found);
              }

              this.setState({ rawValue: e.detail.value!, found });
              // this._onChange(e, flat ? e.detail.value! : { [displayValue]: e.detail.value! });
            }}
            debounce={500}
          />
          <IonButton
            fill='outline'
            slot='end'
            onClick={() => {
              this.setState({ autocompleteOpened: true });
              setTimeout(() => {
                const search = document.getElementsByClassName('searchbar-input');
                if (search && search[0]) {
                  (search[0] as any).focus();
                }
              }, 300);
            }}
          >
            View
          </IonButton>
        </IonItem>

        <Dialog opener={this} id='autocompleteOpened' title={label} okLabel='Select' actionsOff>
          {dialog => (
            <>
              <IonListHeader>
                <IonSearchbar
                  placeholder='Search'
                  value={searchText}
                  onIonChange={e => this.setState({ searchText: e.detail.value!, selected: null })}
                  debounce={300}
                ></IonSearchbar>
              </IonListHeader>
              <IonContent>
                <IonList lines='full'>
                  {/* <IonRadioGroup value={(this.state as any).selected} onIonChange={e => this.setState({ selected: e.detail.value! })}> */}
                  {flat
                    ? options
                        .filter(o => o.search(new RegExp(searchText, 'i')) > -1)
                        .map((item: any, index: number) => (
                          <IonItem
                            key={index}
                            detail
                            button
                            onClick={e => {
                              this.selectItem(dialog, item);
                            }}
                          >
                            <IonLabel>{item}</IonLabel>
                            {/* <IonRadio slot='start' value={item} /> */}
                          </IonItem>
                        ))
                    : options
                        .filter((item: any) => item[displayValue].search(new RegExp(searchText, 'i')) > -1)
                        .map((item: any, index: number) => (
                          <IonItem
                            key={index}
                            detail
                            button
                            onClick={e => {
                              this.selectItem(dialog, item);
                            }}
                          >
                            <IonLabel>{item[displayValue]}</IonLabel>
                            {/* <IonRadio slot='start' value={item} /> */}
                          </IonItem>
                        ))}
                  {/* </IonRadioGroup> */}
                </IonList>
              </IonContent>
              {/* <IonToolbar>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonButtons>
                        <IonButton expand='block' fill='solid' onClick={() => dialog.close('cancel')} style={{ width: '50%' }}>
                          Cancel
                        </IonButton>
                        <IonButton
                          expand='block'
                          fill='solid'
                          type='submit'
                          onClick={() => this.selectItem(dialog, (this.state as any).selected)}
                          style={{ width: '50%' }}
                        >
                          Select
                        </IonButton>
                      </IonButtons>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonToolbar> */}
              <IonItemDivider />
            </>
          )}
        </Dialog>

        {/* <pre>{this.stringify(baseEntity)}</pre> */}
      </>
    );
  }
}

export default AutocompleteWidget;
