import { CRUDFactory } from '../../core/CRUDFactory';

export default class CatalogService extends CRUDFactory {
  constructor() {
    super({
      EndPoint: 'Catalog'
    });
  }

  ADAPTER_IN(entity: any) {
    entity.displayValue = entity.Value;

    return entity;
  }

  GetCatalog = async (name: string, qparams: string = '', wantCommonResponse: boolean = false) => {
    try {
      return await this.Get('ByName/' + name + '?' + qparams).then(r => r.map(this.ADAPTER_IN));
    } catch {
      return [];
    }
  };
}
