import React from 'react';
import { IonModal, IonHeader, IonToolbar, IonTitle, IonGrid, IonRow, IonButtons, IonButton, IonCol } from '@ionic/react';
import './DialogWidget.css';

interface Props {
  okLabel?: string | boolean;
  title?: string;
  open?: boolean;
  onClose?(): void;
  fullScreen?: boolean;
  actionsOff?: boolean;
  opener?: any;
  id?: string;
  maxWidth?: any;
  dividersOff?: boolean;
  fixed?: boolean;
  actions?: any;
  esc?: boolean;
  children(dialog: DialogWidget): any;
}

class DialogWidget extends React.Component<Props> {
  static propTypes = {};

  constructor(props: Props) {
    super(props);
    this.close = this.close.bind(this);
  }
  onOk = () => {}; //To be defined on children
  close(...args: any[]) {
    const { opener, id } = this ? (this.props as any) : { opener: undefined, id: undefined };
    if (opener) opener.closeDialog(id, ...args);
  }

  render() {
    const { okLabel, title, open, actionsOff, children, actions, opener, id, fullScreen } = this.props;

    return (
      <IonModal
        isOpen={open || (opener && id ? !!opener.state[id] : false)}
        animated
        cssClass={fullScreen ? 'FullscreenModal' : ''}
        onDidDismiss={() => {
          this.close('cancel');
        }}
      >
        {title && (
          <IonHeader>
            <IonToolbar>
              <IonTitle className='miestilo'>{title}</IonTitle>
            </IonToolbar>
          </IonHeader>
        )}
        {children(this)}

        {!actionsOff &&
          (actions || (
            <IonToolbar>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonButtons>
                      <IonButton expand='block' fill='solid' onClick={() => this.close('cancel')}>
                        Close
                      </IonButton>
                      {okLabel && (
                        <IonButton
                          expand='block'
                          fill='solid'
                          type='submit'
                          onClick={() => {
                            this.onOk();
                          }}
                        >
                          {okLabel === true ? 'OK' : okLabel}
                        </IonButton>
                      )}
                    </IonButtons>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonToolbar>
          ))}
      </IonModal>
    );
  }
}

export default DialogWidget;
