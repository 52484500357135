//PRODUCTION:
let BaseURL = '%%BASEURL%%';
let UniversalCatalogsURL = '%%CATALOGSURL%%';
let AuthURL = '%%AUTHURL%%';

//DEVELOPMENT:
// let Dev_BaseURL = 'http://localhost:5000/';
// let Dev_UniversalCatalogsURL = 'http://localhost:5000/';
// let Dev_AuthURL = 'http://localhost:5000/';
// let Dev_BaseURL = 'https://iot-hub.beta.app.capsonic.com/';
// let Dev_UniversalCatalogsURL = 'https://iot-hub.beta.app.capsonic.com/';
// let Dev_AuthURL = 'https://iot-hub.beta.app.capsonic.com/';
let Dev_BaseURL = 'https://iot-hub.app.capsonic.com/';
let Dev_UniversalCatalogsURL = 'https://iot-hub.app.capsonic.com/';
let Dev_AuthURL = 'https://iot-hub.app.capsonic.com/';

//
BaseURL = BaseURL === '%%BASEURL%%' ? Dev_BaseURL : BaseURL;
UniversalCatalogsURL = UniversalCatalogsURL === '%%CATALOGSURL%%' ? Dev_UniversalCatalogsURL : UniversalCatalogsURL;
AuthURL = AuthURL === '%%AUTHURL%%' ? Dev_AuthURL : AuthURL;

export default {
  BaseURL,
  UniversalCatalogsURL,
  AuthURL
};
