/* eslint-disable */
import {
  IonButton,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonText,
  IonTitle,
  IonToolbar,
  withIonLifeCycle
} from '@ionic/react';
import { withSnackbar } from 'notistack';
import React from 'react';
import UpdateStation from '../../components/IoTNode/UpdateStation';
import FormContainer, { FormProps } from '../../core/FormContainer';
import Autocomplete from '../../widgets/Autocomplete';
import Dialog from '../../widgets/Dialog';
import CatalogService from '../Catalog/catalog.service';
import IoTNodeService from './iotnode.service';

const catalogService = new CatalogService();

const service = new IoTNodeService();
const defaultConfig = {
  service,
  refreshAfterLogin: true,
  lastLogin: {}
};

interface IoTNodeProps extends FormProps {}

class IoTNodeForm extends FormContainer<IoTNodeProps> {
  constructor(props: any) {
    super(props, defaultConfig);
  }

  refresh() {
    console.log('refreshed!');

    let baseEntity = { ...this.state.baseEntity };

    let lastLogin = this.storageGet('lastLogin');
    if (lastLogin) {
      baseEntity.Station = lastLogin.Station;
      baseEntity.Job = lastLogin.Job;
    }

    this.setState({ isLoading: true, lastLogin });

    Promise.all([
      new Promise(resolve => {
        if (baseEntity.Station) {
          return catalogService.GetCatalog('Station', `Value=${baseEntity.Station}`).then(stations => {
            const station = stations[0] || {};
            const jobs = station.Jobs?.split(', ').map((j: any) => {
              return { Value: j, displayValue: j };
            });
            resolve(jobs || []);
          });
        } else {
          resolve([]);
        }
      }),
      catalogService.GetCatalog('Employee').then(users => {
        return users.map((u: any) => {
          var displayValue = u.Value;
          var firstName = u['First Name'];
          var lastName = u['Last Name'];
          displayValue += ` - ${firstName} ${lastName}`;
          return { ...u, displayValue };
        });
      })
    ])
      .then(([jobs, users]) => {
        this.setState({ isDisabled: false, baseEntity, jobs, users });
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 500);
      })
      .catch(ex => {
        alert(ex);
      });
  }

  setup = () => {
    var dto = { ...this.state.baseEntity };
    dto._Job = null;
    dto._User = null;

    this.service.Post('OperatorLogin', dto).then(() => {
      this.storageSet('lastLogin', dto);
      this.success('Saved Successfully.');
    });
  };

  updateStation = () => {
    this.openDialog('updateStation', {}).then(dialogResponse => {
      const station = dialogResponse.feedback;
      const baseEntity = { ...this.state.baseEntity };

      baseEntity.Station = station.Value;
      const jobs =
        station.Jobs?.split(', ').map((j: any) => ({
          Value: j,
          displayValue: j
        })) || [];

      this.storageSet('lastLogin', baseEntity);
      this.setState({ baseEntity, jobs });
      this.success('Station set successfully.');
    });
  };

  ON_CHANGE = (data: any, field?: string, value?: any) => {
    const { isLoading, lastLogin, baseEntity } = this.state as any;
    if (!this.state.isLoading) {
      if (lastLogin.Station != baseEntity.Station || baseEntity.Job || baseEntity.User) {
        console.log(baseEntity, lastLogin);
        this.setState({ formChanged: true });
      }
    }
  };

  render() {
    const { baseEntity } = this.state as any;

    const { jobs } = this.state as any;
    const { users } = this.state as any;

    return (
      <>
        {/* <IonHeader>
          <IonToolbar>
            <IonTitle>Station Login</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <IonContent>
          <IonHeader collapse='condense'>
            <IonToolbar>
              <IonTitle size='large'>Station Login</IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonListHeader>
            <IonLabel>Station</IonLabel>
          </IonListHeader>

          <IonItem onClick={() => this.updateStation()} button>
            <IonLabel>{baseEntity.Station}</IonLabel>
          </IonItem>

          <Autocomplete options={jobs} onChange={this.handleAutocomplete} owner={baseEntity} targetProp='Job' />

          <Autocomplete options={users} onChange={this.handleAutocomplete} owner={baseEntity} targetProp='User' label='Employee' />

          <IonListHeader>
            <IonLabel>Serial</IonLabel>
          </IonListHeader>

          <IonItem>
            <IonInput
              value={baseEntity.Serial}
              onIonChange={e => {
                this.setState({
                  baseEntity: {
                    ...baseEntity,
                    Serial: e.detail.value!
                  },
                  formChanged: true
                });
              }}
              debounce={500}
            />
            <IonButton
              fill='outline'
              slot='end'
              onClick={() => {
                this.setState({
                  baseEntity: {
                    ...baseEntity,
                    Serial: null
                  },
                  formChanged: true
                });
              }}
            >
              Clear
            </IonButton>
          </IonItem>
        </IonContent>
        {(this.state as any).formChanged && (
          <div style={{ padding: 20, backgroundColor: 'white' }}>
            <IonText color='warning' style={{ display: 'block' }}>
              <h5>Do not forget to save new values!</h5>
            </IonText>
          </div>
        )}
        <IonToolbar>
          <IonButton size='default' expand='full' color='secondary' fill='solid' onClick={() => this.setup()}>
            Save Changes
          </IonButton>
        </IonToolbar>

        {/* <pre>{this.stringify(this.storageGet('lastLogin'))}</pre> */}
        {/* <pre>{this.stringify(baseEntity)}</pre> */}

        {(this.state as any).updateStation && (
          <Dialog opener={this} id='updateStation' title='Station' okLabel='Save' actionsOff>
            {dialog => <UpdateStation dialog={dialog} data={(this.state as any).updateStation} />}
          </Dialog>
        )}
      </>
    );
  }
}

export default withIonLifeCycle(withSnackbar(IoTNodeForm as any));
