import { CRUDFactory } from '../../core/CRUDFactory';

export default class IoTDataService extends CRUDFactory {
  constructor() {
    super({
      EndPoint: 'IoTData'
    });
  }
  ADAPTER_IN(entity) {
    ///start:generated:adapterin<<<
    entity.ConvertedCreatedAt = this.toJavascriptDate(entity.CreatedAt);
    ///end:generated:adapterin<<<

    return entity;
  }

  ADAPTER_OUT(entity) {
    ///start:generated:adapterout<<<
    entity.CreatedAt = this.toServerDate(entity.ConvertedCreatedAt);
    ///end:generated:adapterout<<<
  }
}
