import React from 'react';
import { IonPage } from '@ionic/react';
import UserLogin from '../components/IoTNode/UserLogin';
import './Tab1.css';
import App from '../components/App';

export default () => (
  <IonPage>
    <App>
      <UserLogin />
    </App>
  </IonPage>
);
