import React from 'react';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonRefresher, IonRefresherContent } from '@ionic/react';
import IoTDataList from '../components/IoTData/data';
import './Tab2.css';
import App from '../components/App';

let iotDataAPI = {
  refresh: async () => {}
};

const Tab2: React.FC = () => {
  return (
    <IonPage>
      <App>
        {/* <IonHeader>
        <IonToolbar>
          <IonTitle size='small'>IoT-Data Last 50 Entries</IonTitle>
        </IonToolbar>
      </IonHeader> */}
        <IonContent>
          <IonRefresher slot='fixed' onIonRefresh={e => iotDataAPI.refresh().then(() => e.detail.complete())}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonHeader>
            <IonToolbar>
              <IonTitle>IoT-Data Last 50 Entries</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IoTDataList api={iotDataAPI}></IoTDataList>
        </IonContent>
      </App>
    </IonPage>
  );
};

export default Tab2;
