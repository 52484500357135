import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonSearchbar,
  IonToolbar
} from '@ionic/react';
import React from 'react';
import FormContainer, { FormProps } from '../../core/FormContainer';
// import { withSnackbar } from 'notistack';
import CatalogService from '../Catalog/catalog.service';
import IoTNodeService from './iotnode.service';
const catalogService = new CatalogService();

const service = new IoTNodeService();
const defaultConfig = {
  service,
  refreshAfterLogin: true
};

interface IoTNodeProps extends FormProps {}

class IoTNodeForm extends FormContainer<IoTNodeProps> {
  constructor(props: any, config: any) {
    super(props, Object.assign(defaultConfig, config));
  }

  refresh = () => {
    this.setState({ isDisabled: false });
    catalogService.GetCatalog('Station').then(stations => this.setState({ stations }));
    setTimeout(() => {
      const search = document.getElementsByClassName('searchbar-input');
      if (search && search[0]) {
        (search[0] as any).focus();
      }
    }, 300);
  };

  componentDidMount() {
    this.refresh();
  }

  onDialogOk = async () =>
    await this.service
      .Post('UpdateStation', this.state.baseEntity)
      .then(() => {
        const { stations = [] } = this.state as any;
        return (
          //Need to return at least {}, otherwise dialog promise is rejected:
          stations.find((s: any) => s.Value === this.state.baseEntity.Station) || {}
        );
      })
      .then(station => this.props.dialog?.close(station));

  setSearchText = (searchText: string) => {
    this.setState({ searchText });
  };

  render() {
    const { baseEntity } = this.state;

    ///start:generated:render<<<
    const { stations = [] } = this.state as any;
    const { searchText = '' } = this.state as any;
    ///end:generated:render<<<

    return (
      <>
        <IonListHeader>
          <IonSearchbar
            placeholder='Search Station'
            value={searchText}
            onIonChange={e => this.setSearchText(e.detail.value!)}
          ></IonSearchbar>
        </IonListHeader>

        <IonContent>
          <IonList lines='full'>
            <IonRadioGroup value={baseEntity.Station} onIonChange={e => this.handleInputChange(e, 'Station')}>
              {stations
                .filter((item: any) => item.Value.search(new RegExp(searchText, 'i')) > -1)
                .map((item: any, index: number) => (
                  <IonItem key={item.Id}>
                    <IonLabel>{item.Value}</IonLabel>
                    <IonRadio slot='start' value={item.Value} />
                  </IonItem>
                ))}
            </IonRadioGroup>
          </IonList>
        </IonContent>

        {/* <pre>{this.stringify(baseEntity)}</pre> */}

        <IonItemDivider />

        <form
          noValidate
          onSubmit={event => {
            event.preventDefault();
            this.onDialogOk();
          }}
        >
          <IonItem>
            <IonLabel position='stacked'>Supervisor</IonLabel>
            <IonInput
              value={baseEntity.Supervisor}
              //   placeholder='Supervisor Username'
              onIonChange={e => this.handleInputChange(e, 'Supervisor')}
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>Supervisor Password</IonLabel>
            <IonInput
              value={baseEntity.SupervisorPassword}
              //   placeholder='Supervisor Password'
              onIonChange={e => this.handleInputChange(e, 'SupervisorPassword')}
              type='password'
            ></IonInput>
          </IonItem>

          <IonToolbar>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButtons>
                    <IonButton expand='block' fill='solid' style={{ width: '50%' }} onClick={() => this.props.dialog.close()}>
                      Cancel
                    </IonButton>
                    <IonButton expand='block' fill='solid' style={{ width: '50%' }} type='submit'>
                      Select
                    </IonButton>
                  </IonButtons>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
          <IonItemDivider />
        </form>
      </>
    );
  }
}

export default IoTNodeForm;
